import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import DefaultHeaderIcon from '../../res/chromium.svg';

import * as classes from './win.module.scss';

function WinHeader({ winId, title, tabCount, Icon, headerTitleRef, onClick, onTitleClick, onKeyDown, children }) {
  const handleTitleClick = (e) => {
    onTitleClick(e, winId);
  };

  const handleTitleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.click();
    }
  };

  return (
    <div className={classes.header} onClick={onClick} onKeyDown={onKeyDown}>
      <div className={classes.headerInner}>
        <div className={classes.headerDetailsContainer}>
          <div className={classes.headerIcon}>
            <SvgIcon>{Icon ? <Icon /> : <DefaultHeaderIcon />}</SvgIcon>
          </div>
          <div
            id={`win-${winId}-label`}
            className={classes.headerTitle}
            onClick={handleTitleClick}
            onKeyPress={handleTitleKeyPress}
            tabIndex='1'
            ref={headerTitleRef}
          >
            {title}
          </div>
          {/* <div className={classes.headerCount}>{`(${tabCount})`}</div> */}
        </div>
        <div className={classes.headerActionsContainer}>{children}</div>
      </div>
    </div>
  );
}

WinHeader.propTypes = {
  winId: PropTypes.string.isRequired,
  title: PropTypes.string,
  tabCount: PropTypes.number,
  Icon: PropTypes.elementType,
  className: PropTypes.string,
  onTitleClick: PropTypes.func,
  onKeyDown: PropTypes.func.isRequired,
  headerTitleRef: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export default React.memo(WinHeader);
