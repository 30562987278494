import React, { useState, useEffect } from 'react';
import { Link } from "gatsby";
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';

import { PUBLIC_WINDOWS_URL } from '../config';
import Win from '../components/shared-win';

import LogoIcon from '../res/logo.svg';
import * as classes from './w.module.scss';

export default function Window({ location }) {
  // 672cffcbe0272752908ceafe62b01850df6fbf78
  // a5e0f16ea78586669e09b9c976cc6823729db1cc
  const [win, setWin] = useState(null);
  const [, winId] = location.pathname.split('/w/');
  const url = `${PUBLIC_WINDOWS_URL}${winId}.json`;

  useEffect(() => {
    async function fetchData(url) {
      try {
        const res = await fetch(url);
        if (!res.ok) {
          throw Error(`Network response was not ok: ${res.status}`);
        }
        const json = await res.json();
        setWin(json);
      } catch (err) {
        console.error(err);
      }
    }
    fetchData(url);
  }, [url]);

  return !win ? null : (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <Win
          id={win.id}
          title={win.title}
          tabs={win.tabs}
        />
      </Container>
      <div className={classes.logo}>
        <Link to='/'>
          <div className={classes.logoIconContainer}>
            <SvgIcon className={classes.logoIcon}>
              <LogoIcon/>
            </SvgIcon>
          </div>
        </Link>
      </div>
    </div>
  );
}
