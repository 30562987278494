import React from 'react';
import PropTypes from 'prop-types';

import * as classes from './tab.module.scss';

const createFaviconImage = (siteUrl) => {
  try {

    const url = new URL(siteUrl);
    const { hostname } = url;
    const t = hostname.split('.');
    let rootHostname = hostname;
    if (t.length > 1) {
      rootHostname = `${t[t.length - 2]}.${t[t.length - 1]}`;
    }

    const faviconUrl = `https://icons.duckduckgo.com/ip2/${rootHostname}.ico`;
    return `url("${faviconUrl}")`;
  } catch {
    const faviconUrl = `https://www.google.com/s2/favicons?domain_url=${siteUrl}`;
    return `url("${faviconUrl}")`;
  }
};

function Tab({
  className = '',
  id,
  winId,
  title,
  domain,
  url,
  pinned,
  onTabClick,
  onTitleClick,
  onKeyDown,
  children,
}) {
  return (
    <li
      className={`${classes.tab} ${className}`}
      id={id}
      data-tab-id={id}
      data-win-id={winId}
      onClick={onTabClick}
      onKeyDown={onKeyDown}
      pinned={pinned ? '' : null}
      role='option'
      aria-selected='false'
    >
      <div className={classes.tabInner}>
        <div className={classes.tabIcon} style={{ backgroundImage: createFaviconImage(url) }}></div>

        <div className={classes.tabEntryContainer}>
          <a
            className={classes.tabTitle}
            onClick={onTitleClick}
            href={url}
            target='_blank'
            rel='noopener noreferrer'
            tabIndex='-1'
          >
            {title}
          </a>
          <div role='presentation' className={classes.tabDomain}>
            {domain}
          </div>
        </div>

        <div></div>
        <div className={classes.tabActionsContainer}>{children}</div>
      </div>
    </li>
  );
}

Tab.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  id: PropTypes.string,
  winId: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  audible: PropTypes.bool,
  muted: PropTypes.bool,
  pinned: PropTypes.bool,
  onTabClick: PropTypes.func,
  onTitleClick: PropTypes.func,
  onKeyDown: PropTypes.func.isRequired,
};

export default React.memo(Tab);
