import React from 'react';
import PropTypes from 'prop-types';

import { Win as WinBase } from '../win';
import Header from './header';
import Tabs from './tabs';

function Win({ id, title, tabs }) {
  const handleWinTitleClick = (e, winId) => {
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();
    // this.props.onOpenWin(id);
  };

  return (
    <WinBase
      id={id}
      header={<Header winId={id} title={title} onTitleClick={handleWinTitleClick} />}
      tabs={<Tabs winId={id} tabs={tabs} />}
    />
  );
}

Win.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
};

export default Win;
