import React from 'react';
import PropTypes from 'prop-types';

import * as classes from './tabs.module.scss';

export default class Tabs extends React.PureComponent {
  render() {
    const {
      className = '',
      Tab,
      winId,
      tabs,
      tabIds,
      tabsRef,
      onTabClick,
      onTabTitleClick,
      onTabKeyDown,
      ...rest
    } = this.props;

    return (
      <div className={`${classes.tabs} ${className}`}>
        <ol
          className={classes.tabsInner}
          ref={tabsRef}
          role='listbox'
          aria-multiselectable='true'
          aria-labelledby={`win-${winId}-label`}
        >
          {tabs
            ? tabs.map((tab, index) => (
                <Tab
                  key={index}
                  tab={tab}
                  onTabClick={onTabClick}
                  onTabTitleClick={onTabTitleClick}
                  onKeyDown={onTabKeyDown}
                  {...rest}
                />
              ))
            : tabIds.map((tabId) => (
                <Tab
                  key={tabId}
                  tabId={tabId}
                  onTabClick={onTabClick}
                  onTabTitleClick={onTabTitleClick}
                  onKeyDown={onTabKeyDown}
                  {...rest}
                />
              ))}
        </ol>
      </div>
    );
  }
}

Tabs.propTypes = {
  className: PropTypes.string,
  Tab: PropTypes.elementType.isRequired,
  winId: PropTypes.string.isRequired,
  tabs: PropTypes.array,
  tabIds: PropTypes.array,
  tabsRef: PropTypes.object.isRequired,
  onTabTitleClick: PropTypes.func,
  onTabClick: PropTypes.func,
  onTabKeyDown: PropTypes.func.isRequired,
};
