import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as TabsBase } from '../win';
import Tab from './tab';

function Tabs({ winId, tabs, tabsRef, onTabKeyDown }) {
  const handleTabTitleClick = (e, tabId) => {
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();
  };

  return (
    <TabsBase
      winId={winId}
      tabs={tabs}
      Tab={Tab}
      onTabTitleClick={handleTabTitleClick}
      onTabKeyDown={onTabKeyDown}
      tabsRef={tabsRef}
    />
  );
}

Tabs.propTypes = {
  winId: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  onTabKeyDown: PropTypes.func,
  tabsRef: PropTypes.object,
};

Tabs.defaultProps = {
  tabs: [],
};

export default Tabs;
