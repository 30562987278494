import React from 'react';
import PropTypes from 'prop-types';

import * as classes from './win.module.scss';

export default class Win extends React.PureComponent {
  constructor(props) {
    super(props);
    this.headerTitleRef = React.createRef();
    this.tabsRef = React.createRef();
  }

  handleHeaderKeyDown = (e) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      e.preventDefault();
      const tabElements = this.getTabElements();
      if (!tabElements.length) {
        return;
      }
      const [firstElement] = tabElements.filter((element) => !element.hasAttribute('hidden'));
      const link = firstElement.querySelector('a');
      link && link.focus();
    }
    this.props.header.props.onKeyDown && this.props.header.props.onKeyDown(e);
  };

  handleTabKeyDown = (e) => {
    const validKeys = ['ArrowDown', 'ArrowUp', 'ArrowLeft'];

    if (!validKeys.includes(e.key)) {
      return this.props.tabs.props.onTabKeyDown && this.props.tabs.props.onTabKeyDown(e);
    }

    const tabElements = this.getTabElements();
    const visibleTabElements = tabElements.filter((element) => !element.hasAttribute('hidden'));

    if (!visibleTabElements.length || visibleTabElements.length === 1) {
      return this.props.tabs.props.onTabKeyDown && this.props.tabs.props.onTabKeyDown(e);
    }

    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        // TODO: Focus next tab or first if last.
        const focusedTabNodeIndex = this.findVisibleTabNodeIndex(e.target);
        if (focusedTabNodeIndex === visibleTabElements.length - 1) {
          visibleTabElements[0].querySelector('a').focus();
        } else {
          visibleTabElements[focusedTabNodeIndex + 1].querySelector('a').focus();
        }
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        const focusedTabNodeIndex = this.findVisibleTabNodeIndex(e.target);
        if (focusedTabNodeIndex === 0) {
          visibleTabElements[tabElements.length - 1].querySelector('a').focus();
        } else {
          visibleTabElements[focusedTabNodeIndex - 1].querySelector('a').focus();
        }
        break;
      }
      case 'ArrowLeft': {
        e.preventDefault();
        this.headerTitleRef && this.headerTitleRef.current.focus();
        break;
      }
      default: {
        break;
      }
    }
    this.props.tabs.props.onTabKeyDown && this.props.tabs.props.onTabKeyDown(e);
  };

  findVisibleTabNodeIndex(node) {
    const elements = this.getTabElements();
    return elements.filter((element) => !element.hasAttribute('hidden')).findIndex((element) => element.contains(node));
  }

  getTabElements() {
    if (!this.tabsRef || !this.tabsRef.current) {
      return [];
    }
    return Array.from(this.tabsRef.current.children);
  }

  render() {
    const { className, id, ariaLabel = 'browser window', isHidden, header, tabs } = this.props;

    return (
      <section
        id={id}
        aria-label={ariaLabel}
        className={`${classes.win} ${isHidden ? 'hidden' : ''} ${className}`.trim()}
      >
        <div className={classes.inner}>
          <header.type {...header.props} headerTitleRef={this.headerTitleRef} onKeyDown={this.handleHeaderKeyDown} />
          <tabs.type {...tabs.props} tabsRef={this.tabsRef} onTabKeyDown={this.handleTabKeyDown} />
        </div>
      </section>
    );
  }
}

Win.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  isHidden: PropTypes.bool,
  header: PropTypes.element,
  tabs: PropTypes.element,
  children: PropTypes.any,
};
