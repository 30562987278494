import React from 'react';
import PropTypes from 'prop-types';
import { Tab as TabBase } from '../win';

function Tab({ tab, ...rest }) {
  const handleTitleClick = (e) => {
    // return this.props.onTabTitleClick(e, this.props.tab.id);
  };

  let domain = '';
  try {
    const url = new URL(tab.url);
    domain = url.hostname;
  } catch {}

  return (
    <TabBase
      title={tab.title}
      domain={domain}
      url={tab.url}
      pinned={false}
      {...rest}
      onTitleClick={handleTitleClick}
    />
  );
}

Tab.propTypes = {
  tab: PropTypes.object.isRequired,
  onTabTitleClick: PropTypes.func.isRequired,
};

Tab.defaultProps = {
  tab: {},
};

export default Tab;
